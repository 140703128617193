function sideForm(){
    $('.side-form').toggleClass('side-form_opened');
    $('.side-form__btn-content_close, .side-form__btn-content_open').toggleClass('side-form__btn-content_hide');
    $('.side-form__overlay').toggleClass('side-form__overlay_revealed');
  }
  
  $('.side-form__btn, .side-form__overlay').click(sideForm);
  
  $('body').keyup(function(e){
    if ($('.side-form_opened').length && e.which == 27){
      sideForm();
    }
  });
  